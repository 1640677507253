import Image from "next/image";
import StarsAndSparkles from "@/components/Sparkle";
import Jokes from "@/components/Jokes";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface ScanningModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: string;
}

export const ScanningModal = ({ isOpen, onClose, image }: ScanningModalProps) => {
  const matches = useMediaQuery("(max-width: 56.25em)");

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-black/50 z-50 flex items-center justify-center ${matches ? "p-0" : "p-4"}`}>
      <div className={`bg-white rounded-lg ${matches ? "w-full h-full" : "max-w-lg w-full"}`}>
        <div className="inline-flex flex-col justify-center items-center w-full p-4">
          <div className="w-full flex justify-end items-end">
            <button className="text-blue-600 hover:text-blue-700 px-4 py-2" onClick={onClose}>
              Skip
            </button>
          </div>

          <div className="rounded-full relative">
            <Image
              className="object-cover overflow-hidden rounded-2xl object-center h-[400px] w-[220px]"
              alt=""
              src={image}
              width={1920}
              height={1080}
            />
            <StarsAndSparkles />
          </div>

          <div className="text-center mt-10">
            <p>Have a laugh while we scan your receipt</p>
            <hr className="my-4 border-gray-200" />
            <Jokes />
          </div>
        </div>
      </div>
    </div>
  );
}; 