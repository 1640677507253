"use client";

import { LocationPermissionModal } from "@/app/_components/LocationPermissionModal";
import { ScanningModal } from "@/app/_components/ScanningModal";
import HeaderBanner from "@/components/HeaderBanner";
import LogoAnimation from "@/components/LogoAnimation";
import { PinInput } from "@/components/PinInput";
import Camera from "@/components/ui/camera/camera";
import IconCloudUpload from "@/components/icon/CloudUploadIcon";
import { useFingerprint } from "@/hooks/use-fingerprint";
import { useLocation } from "@/hooks/useLocation";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useVendorSearch } from "@/hooks/useVendorSearch";
import { usePlayGameStore } from "@/store/play-game";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { toast } from "sonner";
import piexif from 'piexifjs';

export default function Home() {
  const { fingerprint, sessionId } = useFingerprint();
  const [image, setImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const matches = useMediaQuery("(max-width: 56.25em)");
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const { showLocationModal, requestLocationPermission } = useLocation();
  const [currentLocation, setCurrentLocation] = useState<{ latitude?: number; longitude?: number } | null>(null);

  const [testImageUrl] = useState<string | null>(
    // "https://dev-playe-sync-image.s3.amazonaws.com/sync-image/WhatsApp+Image+2024-02-19+at+21.05.47_2d84ef7f.jpg"
  );

  const { searchVendorByReceipt, searchVendorByCode } = useVendorSearch({
    onModalClose: () => setIsModalOpen(false),
  });

  const handleImageCapture = async (image: string | ImageData) => {
    const imageStr = typeof image === "string" ? image : "";
    if (!imageStr || !sessionId || !fingerprint) return;

    try {
      setImage(imageStr);
      setIsCameraOpen(false);
      setIsModalOpen(true);

      const location = await requestLocationPermission();
      setCurrentLocation(location);

      setPlayGame({
        image: imageStr,
        fingerprint,
        sessionId,
      });

      const bodyFormData = new FormData();
      let file: File;

      // Convert image to File while preserving all metadata
      if (imageStr.startsWith('data:image/')) {
        // Handle base64 image from camera (with proper header)
        const response = await fetch(imageStr);
        const blob = await response.blob();
        file = new File([blob], "receipt.jpg", { type: blob.type });
      } else if (testImageUrl && imageStr === testImageUrl) {
        // Handle test image URL if provided
        const response = await fetch(testImageUrl);
        const blob = await response.blob();
        file = new File([blob], "receipt.jpg", { type: blob.type });
      } else {
        throw new Error("Unsupported image format");
      }

      bodyFormData.append("Data", file);
      bodyFormData.append("Latitude", location.latitude?.toString() || "");
      bodyFormData.append("Longitude", location.longitude?.toString() || "");
      bodyFormData.append("Fingerprint", fingerprint);
      bodyFormData.append("SessionId", sessionId);

      await searchVendorByReceipt.mutateAsync(bodyFormData);
    } catch (error) {
      console.error('Error processing image:', error);
      toast.error("Failed to process receipt. Please try again.");
    }
  };

  const { playGame, setPlayGame } = usePlayGameStore();

  const handleUploadClick = () => {
    if (testImageUrl) {
      handleImageCapture(testImageUrl);
    } else {
      setIsCameraOpen(true);
    }
  };

  return (
    <div
      style={{
        height: "100svh",
      }}
      className="relative flex flex-col bg-[#0A000B]"
    >
      {loading && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
          <div className="animate-pulse flex space-x-2">
            <div className="w-3 h-3 bg-white rounded-full"></div>
            <div className="w-3 h-3 bg-white rounded-full"></div>
            <div className="w-3 h-3 bg-white rounded-full"></div>
          </div>
        </div>
      )}

      <LocationPermissionModal isOpen={showLocationModal} onRequestPermission={requestLocationPermission} />

      {isCameraOpen && (
        <div className="fixed inset-0 z-50 bg-background">
          <Camera
            onClosed={() => setIsCameraOpen(false)}
            onCapturedImages={(images: string[]) => {
              if (images.length > 0) {
                handleImageCapture(images[0]);
              }
            }}
            fingerprint={fingerprint || undefined}
            latitude={currentLocation?.latitude || null}
            longitude={currentLocation?.longitude || null}
          />
        </div>
      )}

      <ScanningModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} image={image || ""} />

      <div className="absolute top-3 left-1/2 -translate-x-1/2 z-40 w-full px-2 md:px-4 max-w-6xl">
        <HeaderBanner />
      </div>

      <div className="md:h-1/3 z-20 h-[46%] relative bg-[#0A000B] overflow-hidden object-cover object-center w-full">
        <LogoAnimation />
        <div className="top-0 h-full w-full absolute z-10 bg-gradient-to-b from-transparent to-[#0A000B]"></div>
      </div>

      <div className="md:h-2/3 h-[54%] max-w-2xl mx-auto flex items-center md:justify-start justify-between flex-col px-4 py-2">
        {!isCode ? (
          <div className="w-full">
            <div onClick={handleUploadClick} className="flex items-center flex-col">
              <IconCloudUpload />
              <p className="text-center text-gray-300 md:text-xl text-lg">Tap here</p>
            </div>
            <p className="text-blue-800 cursor-pointer text-center w-full mt-1" onClick={() => setIsCode(!isCode)}>
              Receipt not scanning?
            </p>
          </div>
        ) : (
          <div className="mt-10">
            <PinInput
              length={6}
              onComplete={(code) => {
                setLoading(true);
                searchVendorByCode(code).finally(() => setLoading(false));
              }}
            />
            <p className="text-center mt-10">Enter Store Code</p>
            <p className="text-blue-800 text-center cursor-pointer mt-1" onClick={() => setIsCode(!isCode)}>
              Capture Image?
            </p>
          </div>
        )}

        <p className="md:pt-10 font-sans text-xs text-blue-200/60">
          By creating an account, you agree to the <text className="text-blue-600 underline">Terms of Service</text>.
          For more information about <text className="text-blue-600 underline">Playe's privacy practices</text>, see the
          Playe Privacy Statement. We'll occasionally send you account-related emails.
        </p>
      </div>
    </div>
  );
}

const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
