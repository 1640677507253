"use client";
import { useState } from "react";

// Custom PIN Input component
export const PinInput = ({ length, onComplete }: { length: number; onComplete: (value: string) => void; }) => {
  const [pins, setPins] = useState(Array(length).fill(""));

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return;

    const newPins = [...pins];
    newPins[index] = value;
    setPins(newPins);

    if (value && index < length - 1) {
      const nextInput = document.querySelector(`input[name='pin-${index + 1}']`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
    }

    if (newPins.every((pin) => pin !== "")) {
      onComplete(newPins.join(""));
    }
  };

  return (
    <div className="flex gap-2">
      {pins.map((pin, index) => (
        <input
          key={index}
          type="text"
          name={`pin-${index}`}
          maxLength={1}
          value={pin}
          onChange={(e) => handleChange(index, e.target.value)}
          className="w-12 h-12 text-center border-b-2 border-white bg-transparent text-white focus:outline-none focus:border-blue-500" />
      ))}
    </div>
  );
};
