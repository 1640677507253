import axios from "axios";
import { toast } from "sonner";

const createAxiosInstance = async (baseURL: string) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {

      if (error.response.status === 401) {
        toast.error("Session expired. Please sign in again");
        return;
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;

export const createFetchInstance = (baseURL: string) => {
  return async (url: string, options: RequestInit) => {
    const headers = new Headers(options.headers);

    return fetch(baseURL + url, {
      ...options,
      headers,
    });
  };
};


