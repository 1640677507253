"use client";

import { ArrowLeftRight, Check, GalleryVerticalEnd, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CameraView } from "./camera-view";
import { FC, useRef, useState } from "react";
import { useCamera } from "@/components/ui/camera/camera-provider";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CameraType } from "./camera-types";
import piexif from 'piexifjs';

interface CameraProps {
  onClosed: () => void;
  onCapturedImages: (images: string[]) => void;
  fingerprint?: string;
  latitude?: number | null;
  longitude?: number | null;
}

const Camera: FC<CameraProps> = ({ onClosed, onCapturedImages, fingerprint, latitude, longitude }) => {
  const camera = useRef<CameraType | null>(null);
  const { images, addImage, numberOfCameras, resetImages, stopStream } = useCamera();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const attachExifData = async (imageBlob: Blob): Promise<Blob> => {
    // Create a new image element to get dimensions
    const img = new Image();
    await new Promise((resolve) => {
      img.onload = resolve;
      img.src = URL.createObjectURL(imageBlob);
    });

    // Convert blob to base64 for piexif
    const reader = new FileReader();
    const base64Image = await new Promise<string>((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });

    // Get current date and time in EXIF format (YYYY:MM:DD HH:MM:SS)
    const now = new Date();
    const dateTimeOriginal = now.toISOString()
      .replace(/[-T]/g, ':')
      .replace(/\.\d+Z$/, '');
    
    // Get camera capabilities if available
    let cameraSettings = {
      exposureTime: '1/100',
      fNumber: '2.8',
      isoSpeed: '100',
      focalLength: '4.2'
    };

    if (camera.current?.getCapabilities) {
      const capabilities = camera.current.getCapabilities();
      if (capabilities) {
        cameraSettings = {
          exposureTime: capabilities.exposureTime?.toString() || '1/100',
          fNumber: capabilities.fNumber?.toString() || '2.8',
          isoSpeed: capabilities.iso?.toString() || '100',
          focalLength: capabilities.focalLength?.toString() || '4.2'
        };
      }
    }

    // Get browser and OS info for device metadata
    const userAgent = window.navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const deviceMake = isMobile ? 
      (userAgent.includes('iPhone') ? 'Apple' : 'Android Device') : 
      'Web Browser';
    const deviceModel = isMobile ?
      (userAgent.includes('iPhone') ? 'iPhone' : 'Android Phone') :
      'Camera';

    // Convert GPS coordinates to EXIF format (degrees, minutes, seconds)
    const convertToExifGPS = (coordinate: number | null | undefined) => {
      if (!coordinate) return [[0, 1], [0, 1], [0, 1]];
      const absolute = Math.abs(coordinate);
      const degrees = Math.floor(absolute);
      const minutes = Math.floor((absolute - degrees) * 60);
      const seconds = Math.floor(((absolute - degrees - minutes/60) * 3600) * 100) / 100;
      return [[degrees, 1], [minutes, 1], [seconds * 100, 100]];
    };
    
    // Prepare EXIF data in piexif format
    const exifObj = {
      "0th": {
        [piexif.ImageIFD.Make]: deviceMake,
        [piexif.ImageIFD.Model]: deviceModel,
        [piexif.ImageIFD.Software]: "Camera App v1.0",
        [piexif.ImageIFD.DateTime]: dateTimeOriginal,
        [piexif.ImageIFD.XResolution]: [72, 1],
        [piexif.ImageIFD.YResolution]: [72, 1],
        [piexif.ImageIFD.Copyright]: "All Rights Reserved"
      },
      "Exif": {
        [piexif.ExifIFD.DateTimeOriginal]: dateTimeOriginal,
        [piexif.ExifIFD.DateTimeDigitized]: dateTimeOriginal,
        [piexif.ExifIFD.PixelXDimension]: img.naturalWidth,
        [piexif.ExifIFD.PixelYDimension]: img.naturalHeight,
        [piexif.ExifIFD.ExposureTime]: [1, 100], // 1/100 sec
        [piexif.ExifIFD.FNumber]: [28, 10], // f/2.8
        [piexif.ExifIFD.ISOSpeedRatings]: 100,
        [piexif.ExifIFD.FocalLength]: [42, 10], // 4.2mm
        [piexif.ExifIFD.ColorSpace]: 1, // sRGB
        [piexif.ExifIFD.ExifVersion]: "0231",
        [piexif.ExifIFD.ComponentsConfiguration]: "\x01\x02\x03\x00", // RGB
        [piexif.ExifIFD.FlashpixVersion]: "0100",
        [piexif.ExifIFD.MakerNote]: `Fingerprint:${fingerprint || "unknown"}`,
      },
      "GPS": {
        [piexif.GPSIFD.GPSVersionID]: [2, 2, 0, 0],
        [piexif.GPSIFD.GPSLatitudeRef]: latitude && latitude >= 0 ? "N" : "S",
        [piexif.GPSIFD.GPSLatitude]: convertToExifGPS(latitude),
        [piexif.GPSIFD.GPSLongitudeRef]: longitude && longitude >= 0 ? "E" : "W",
        [piexif.GPSIFD.GPSLongitude]: convertToExifGPS(longitude),
        [piexif.GPSIFD.GPSDateStamp]: dateTimeOriginal.split(' ')[0] || '',
        [piexif.GPSIFD.GPSTimeStamp]: (() => {
          try {
            const timePart = dateTimeOriginal.split(' ')[1];
            if (!timePart) return [[0, 1], [0, 1], [0, 1]];
            return timePart.split(':').map(n => [parseInt(n) || 0, 1]);
          } catch (error) {
            return [[0, 1], [0, 1], [0, 1]];
          }
        })(),
        [piexif.GPSIFD.GPSProcessingMethod]: "NETWORK",
      }
    };

    // Create EXIF binary
    const exifbytes = piexif.dump(exifObj);

    // Insert EXIF into JPEG
    const newImageData = piexif.insert(exifbytes, base64Image);

    // Convert back to blob
    const byteString = atob(newImageData.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    
    return new Blob([ab], { type: 'image/jpeg' });
  };

  const handleCapture = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (camera.current) {
      const imageData = camera.current.takePhoto();
      if (imageData) {
        // Convert base64 to blob
        const response = await fetch(imageData);
        const blob = await response.blob();
        
        // Attach EXIF data
        const processedBlob = await attachExifData(blob);
        
        // Convert back to base64 for preview
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setPreviewImage(base64data);
        };
        reader.readAsDataURL(processedBlob);
      }
    }
  };

  const handleUsePhoto = () => {
    if (previewImage) {
      addImage(previewImage);
      setPreviewImage(null);
      handleOnCapturedImages([previewImage]);
    }
  };

  const handleRetake = () => {
    setPreviewImage(null);
  };

  const handleOnClosed = () => {
    stopStream();
    onClosed();
  };

  const handleOnCapturedImages = (images: string[]) => {
    onCapturedImages(images);
    resetImages();
    handleOnClosed();
  };

  return (
    <div className="z-10 flex min-w-[calc(100vw_-_theme(spacing.4))] flex-1 flex-col">
      <div className="relative w-full">
        <div className="absolute z-10 w-full md:h-[calc(93vh_-_theme(spacing.12))] md:w-[20%]">
          <div className="relative top-0 left-2">
            <Button
              className="p-4 rounded-full opacity-40 hover:opacity-100"
              size={"icon"}
              variant={images.length > 0 ? "destructive" : "default"}
              onClick={handleOnClosed}
            >
              <X className="fixed w-6 h-6" />
            </Button>
          </div>
        </div>

        {previewImage ? (
          <div className="relative w-full h-svh">
            <img src={previewImage} alt="Preview" className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 p-4 bg-black/50 flex justify-between">
              <Button 
                onClick={handleRetake}
                variant="ghost"
                className="text-white hover:text-white hover:bg-black/30"
              >
                Retake
              </Button>
              <Button 
                onClick={handleUsePhoto}
                variant="ghost"
                className="text-white hover:text-white hover:bg-black/30"
              >
                Use Photo
              </Button>
            </div>
          </div>
        ) : (
          <>
            <CameraView ref={camera} />
            <div className="absolute bottom-0 left-[45%] z-20 md:bottom-auto md:left-auto md:right-14 md:top-[50%]">
              <Button
                className={cn("group h-12 w-12 rounded-full p-8 opacity-40 hover:opacity-100")}
                size={"icon"}
                variant={"default"}
                onClick={handleCapture}
              >
                <div className="fixed rounded-full h-11 w-11 bg-primary-foreground group-hover:bg-primary-foreground/60"></div>
              </Button>
            </div>

            <div className={cn("absolute z-10 w-full md:right-0 md:top-0 md:h-[calc(93vh_-_theme(spacing.12))] md:w-[20%]")}>
              {numberOfCameras > 0 && (
                <div className="absolute bottom-0 z-10 right-6 md:bottom-0 md:right-14 md:top-auto">
                  <SwitchCamera />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function SwitchCamera() {
  const { devices, setActiveDeviceId, activeDeviceId, switchCamera } = useCamera();

  if (devices.length === 2) {
    return (
      <Button
        variant="default"
        size="icon"
        className="p-4 rounded-full opacity-40 hover:opacity-100"
        onClick={switchCamera}
      >
        <ArrowLeftRight className="fixed w-6 h-6" />
      </Button>
    );
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"default"} size={"icon"} className="p-4 rounded-full opacity-40 hover:opacity-100">
          <ArrowLeftRight className="fixed w-6 h-6 " />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Switch Camera</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Select
            onValueChange={(value: string) => {
              setActiveDeviceId(value);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choose Camera" />
            </SelectTrigger>
            <SelectContent>
              {devices.map((device) => (
                <SelectItem key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

function Gallery() {
  const { images, removeImage } = useCamera();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="p-4 rounded-full opacity-40 hover:opacity-100 " size={"icon"} variant={"default"}>
          <GalleryVerticalEnd className="fixed w-6 h-6 " />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{images.length} Photos</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[calc(80vh-_theme(spacing.16))]">
          <div className="grid grid-cols-2 gap-2 ">
            {images.map((image, index) => (
              <div key={index} className="relative ">
                <img src={image} alt="captured" />
                <Button
                  className="absolute w-6 h-6 p-2 rounded-full right-2 top-2 opacity-40 hover:opacity-100 "
                  size={"icon"}
                  variant={"destructive"}
                  onClick={() => {
                    removeImage(index);
                  }}
                >
                  <X className="fixed w-4 h-4 " />
                </Button>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

export default Camera;
