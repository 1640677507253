import { useState, useEffect } from 'react';
import { toast } from 'sonner';

interface UseLocationReturn {
  showLocationModal: boolean;
  setShowLocationModal: (show: boolean) => void;
  requestLocationPermission: () => Promise<{ latitude?: number; longitude?: number }>;
}

export const useLocation = (): UseLocationReturn => {
  const [showLocationModal, setShowLocationModal] = useState(false);

  const requestLocationPermission = async () => {
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        });
      });

      setShowLocationModal(false);
      const { latitude, longitude } = position.coords;
      
      if (latitude && longitude) {
        toast.dismiss();
      }

      return { latitude, longitude };
    } catch (error) {
      if (!(error instanceof GeolocationPositionError)) return {};

      let message = "";
      const geoError = error;

      switch (geoError.code) {
        case geoError.PERMISSION_DENIED:
          message = "Location access is required to find stores near you. Please enable location in your settings.";
          break;
        case geoError.POSITION_UNAVAILABLE:
          message = "Unable to get your location. Please check if location is enabled on your device.";
          break;
        case geoError.TIMEOUT:
          message = "Location request timed out. Please try again.";
          break;
        default:
          message = "Location access is required to find stores near you.";
      }

      setShowLocationModal(true);
      toast.error("Location Access Needed", {
        description: message,
      });

      if (geoError.code === geoError.PERMISSION_DENIED) {
        toast.info("How to Enable Location", {
          description: `For Android:
                      1. Open Settings
                      2. Go to Privacy/Location
                      3. Enable Location
                      4. Return & refresh

                      For iOS:
                      1. Open Settings
                      2. Go to Privacy & Security
                      3. Location Services
                      4. Enable & find browser
                      5. Return & refresh`,
          duration: 10000,
        });
      }

      return {};
    }
  };

  useEffect(() => {
    requestLocationPermission();
  }, []);

  return {
    showLocationModal,
    setShowLocationModal,
    requestLocationPermission
  };
}; 