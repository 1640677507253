interface LocationPermissionModalProps {
  isOpen: boolean;
  onRequestPermission: () => void;
}

export const LocationPermissionModal = ({ isOpen, onRequestPermission }: LocationPermissionModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-[#1A1B1E] p-6 rounded-lg max-w-lg w-full">
        <h2 className="text-xl font-bold mb-4 text-white">Location Access Required</h2>
        <p className="mb-6 text-gray-300">
          This app requires location access to find stores near you. Please enable location access to continue.
        </p>
        <div className="space-y-4">
          <div className="bg-blue-900/30 p-4 rounded-lg">
            <h3 className="font-bold mb-2 text-white">How to Enable Location:</h3>
            <div className="text-left text-sm text-gray-300 space-y-2">
              <p className="font-bold">For Chrome/Edge (Android):</p>
              <ol className="list-decimal list-inside ml-2 space-y-1">
                <li>Tap the lock icon in the address bar</li>
                <li>Tap "Site settings"</li>
                <li>Find "Location" and select "Allow"</li>
                <li>Refresh this page</li>
              </ol>

              <p className="font-bold mt-4">For Safari (iOS):</p>
              <ol className="list-decimal list-inside ml-2 space-y-1">
                <li>Open Settings</li>
                <li>Scroll down to Safari</li>
                <li>Tap "Location"</li>
                <li>Select "Allow"</li>
                <li>Return here and refresh</li>
              </ol>
            </div>
          </div>

          <button
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
            onClick={onRequestPermission}
          >
            Try Again
          </button>

          <p className="text-sm text-gray-400">After enabling location, click "Try Again" or refresh the page</p>
        </div>
      </div>
    </div>
  );
}; 